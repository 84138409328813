.c-wrapper {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.c-footer {
  position: sticky;
  bottom: 0;
}
.c-main {
  padding-bottom: 2em;
  height: calc(100vh - 110px);
}
.c-body {
  overflow: hidden;
  padding-top: 0 !important;
}
.c-main .container-fluid {
  height: 100%;
}
.c-main .fade {
  height: 100%;
}
.c-main .fade .row {
  height: 100%;
}
