.modal-xxl {
  min-width: 98%;
  margin: 1% !important;
}

.rw-widget-picker {
  border: #e4e7ea 1px solid !important;
}

.rw-widget-input {
  background-color: #e4e7ea;
  box-shadow: none !important;
}

.rw-cell:hover {
  background-color: #ced2d8 !important;
  border-color: #ced2d8 !important;
}

.rw-cell.rw-state-selected,
.rw-cell.rw-state-selected:hover {
  background-color: #2a1ab9 !important;
  border-color: #2a1ab9 !important;
  color: #fff;
}

.nav-tabs .nav-link {
  border-color: #d8dbe0 #d8dbe0 #c4c9d0 !important;
  color: #768192 !important;
}

.child-sc-dropdown tbody{
  background-color: rgba(0, 0, 21, 0.05) !important;
}
